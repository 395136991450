<template>
  <v-card max-width="500px">
    <v-container>
      <v-card-title>
        Add Appointment
        <v-spacer></v-spacer>
        <v-btn @click="close()" color="red" text>Cancel</v-btn>
      </v-card-title>

      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-autocomplete
              v-model="selectedSpecialty"
              :items="specialties"
              label="Filter Specialty"
              hide-details="hint"
              outlined
              rounded
              dense
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-autocomplete
              v-model="selectedDoctor"
              :items="filteredDoctors"
              item-text="Name"
              item-value="id"
              label="Doctor"
              return-object
              hide-details="hint"
              no-data-text="No Doctors Found"
              dense
              outlined
              rounded
              required
              :rules="rules"
            >
              <template v-slot:selection="{ item }">
                {{ item.Name }}
              </template>

              <template v-slot:item="{ item }">
                <v-list-item-avatar>
                  <img v-if="!item.photoURL" src="@/assets/avatar.png" />
                  <img v-else :src="item.photoURL" /> </v-list-item-avatar
                >{{ item.Name }}
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="12" v-show="false">
            <v-select
              v-model="selectedPatient"
              :items="patients"
              disabled
              item-text="displayName"
              item-value="id"
              label="Patient"
              return-object
              hide-details="hint"
              dense
              outlined
              rounded
              required
              :rules="rules"
            ></v-select>
          </v-col>

          <v-dialog
            ref="dialog"
            v-model="dialog"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <v-date-picker
              v-model="date"
              :allowed-dates="allowedDates"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dialog = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-col cols="12" md="12">
            <v-text-field
              v-model="computedDateFormattedMomentjs"
              :value="computedDateFormattedMomentjs"
              append-icon="event"
              label="Date"
              dense
              outlined
              rounded
              hide-details="hint"
              :rules="rules"
              @click:append="dialog = true"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="startTime"
              :items="times"
              :rules="rules"
              item-text="time"
              item-value="time"
              label="Start Time"
              no-data-text="No available appointments"
              rounded
              outlined
              dense
              required
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="endTime"
              :items="times2"
              :rules="rules"
              label="End Time"
              rounded
              outlined
              dense
              required
              disabled
            ></v-select>
          </v-col>
          <v-col cols="12" md="12">
            <v-checkbox
              v-model="firstTime"
              label="First Time Appointment?"
              hide-details="messages"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-btn @click="createAppt" :disabled="!valid" color="primary" block
          >Create</v-btn
        >
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import * as fb from '@/firebase'
import * as moment from 'moment'
export default {
  name: 'PatientAddAppointmentDialog',
  props: ['patient'],
  data() {
    return {
      selectedPatient: null,
      selectedDoctor: null,
      url: '',
      path: '',
      practice: {
        id: ''
      },
      firstTime: false,
      firstAppt: 15,
      returningAppt: 30,
      adHoc: false,
      patients: [],
      doctors: [],
      filteredDoctors: [],
      date: new Date().toISOString().substr(0, 10),
      dateNoDash: null,
      dialog: false,
      valid: false,
      repeat: true,
      name: 'Office Hours',
      startTime: null,
      endTime: null,
      endFifteen: '',
      endThirty: '',
      selectedSpecialty: 'Any',
      specialties: ['Any'],
      start: '',
      end: '',
      rules: [v => !!v || 'Required'],
      availableHours: [],
      allowedDays: [],
      todaysAppointments: [],
      allOfficeHours: [],
      eliminateTime: [],
      yesterday: fb.db.app.firebase_.firestore.Timestamp.fromDate(
        new Date(
          moment()
            .subtract(1, 'day')
            .toDate()
        )
      )
    }
  },
  watch: {
    selectedSpecialty: function() {
      this.selectedDoctor = null
      if (this.selectedSpecialty === 'Any') {
        this.filteredDoctors = this.doctors
      } else {
        this.filteredDoctors = this.doctors.filter(
          x => x.specialty.name === this.selectedSpecialty
        )
      }
    },
    selectedDoctor: {
      immediate: true,
      async handler() {
        if (this.selectedDoctor) {
          this.allOfficeHours = []
          await fb.db
            .collection(`Proxies/${this.selectedDoctor.id}/OfficeHours`)
            .where('type', '==', 'OperatingHours')
            .orderBy('startTime', 'asc')
            .get()
            .then(snapshot => {
              for (const item of snapshot.docs) {
                const data = item.data()
                // ! getting office hours range
                if (
                  moment(data.startTime.toDate()).isSame(
                    moment(this.date),
                    'day'
                  )
                ) {
                  this.allOfficeHours.push({
                    start: moment(data.startTime.toDate()),
                    end: moment(data.endTime.toDate())
                  })
                }
                if (
                  moment(data.startTime.toDate()).isSameOrAfter(moment(), 'day')
                ) {
                  this.availableHours.push({ id: item.id, ...data })
                }
              }

              for (const item of this.availableHours) {
                let day = moment(item.startTime.toDate()).format('YYYY-MM-DD')
                this.allowedDays.push(day)
              }
              this.date = this.allowedDays[0]
            })

          //Grab appointments for doctor given selected date
          this.todaysAppointments = []
          await fb.db
            .collection(
              `Practices/${this.selectedDoctor.practiceId}/Appointments`
            )
            .where('doctorId', '==', this.selectedDoctor.id)
            .where('startTime', '>', this.yesterday)
            .get()
            .then(snapshot => {
              for (const item of snapshot.docs) {
                const data = item.data()
                if (
                  moment(data.startTime.toDate()).isSame(
                    moment(this.date),
                    'day'
                  )
                ) {
                  this.todaysAppointments.push({
                    start: moment(data.startTime.toDate()),
                    end: moment(data.endTime.toDate())
                  })
                }
              }
            })
        }
      }
    },
    date: {
      immediate: true,
      async handler() {
        //Grab appointments for doctor given selected date

        if (this.selectedDoctor) {
          this.allOfficeHours = []
          await fb.db
            .collection(`Proxies/${this.selectedDoctor.id}/OfficeHours`)
            .where('type', '==', 'OperatingHours')
            .orderBy('startTime', 'asc')
            .get()
            .then(snapshot => {
              for (const item of snapshot.docs) {
                const data = item.data()
                // ! getting office hours range
                if (
                  moment(data.startTime.toDate()).isSame(
                    moment(this.date),
                    'day'
                  )
                ) {
                  this.allOfficeHours.push({
                    start: moment(data.startTime.toDate()),
                    end: moment(data.endTime.toDate())
                  })
                }
              }
            })

          //Grab appointments for doctor given selected date
          this.todaysAppointments = []
          await fb.db
            .collection(
              `Practices/${this.selectedDoctor.practiceId}/Appointments`
            )
            .where('doctorId', '==', this.selectedDoctor.id)
            .where('startTime', '>', this.yesterday)
            .get()
            .then(snapshot => {
              for (const item of snapshot.docs) {
                const data = item.data()
                if (
                  moment(data.startTime.toDate()).isSame(
                    moment(this.date),
                    'day'
                  )
                ) {
                  this.todaysAppointments.push({
                    start: moment(data.startTime.toDate()),
                    end: moment(data.endTime.toDate())
                  })
                }
              }
            })
        }
      }
    },
    startTime: function() {
      this.dateNoDash = this.date.split('-').join('/')
      this.endFifteen = moment(
        new Date(`${this.dateNoDash} ${this.startTime}`)
      ).add(this.returningAppt, 'm')
      this.endThirty = moment(
        new Date(`${this.dateNoDash} ${this.startTime}`)
      ).add(this.firstAppt, 'm')

      if (this.firstTime) {
        this.endTime = this.endThirty.format('hh:mm A')
      } else {
        this.endTime = this.endFifteen.format('hh:mm A')
      }
    },
    firstTime: function() {
      if (this.firstTime) {
        this.endTime = this.endThirty.format('hh:mm A')
      } else {
        this.endTime = this.endFifteen.format('hh:mm A')
      }
    }
  },
  computed: {
    computedDateFormattedMomentjs: {
      get() {
        return this.date ? moment(this.date).format('dddd, MMMM Do YYYY') : ''
      },
      set() {}
    },
    computedStartTime: {
      get() {
        return this.start
          ? moment(this.start.toDate()).format('MMMM D, YYYY hh:mm a')
          : ''
      },
      set() {}
    },
    computedEndTime: {
      get() {
        return this.end ? moment(this.end.toDate()).format('hh:mm a') : ''
      },
      set() {}
    },
    times: function() {
      var eliminate = []
      const times = []
      for (const item of this.allOfficeHours) {
        const duration = moment.duration(item.end.diff(item.start))
        const seconds = duration.asSeconds()
        const startingHour = moment(item.start).hour()
        const startingMinutes = moment(item.start).minutes()

        const steps = 60 * this.returningAppt
        for (let i = 0; i < seconds; i += steps) {
          const d = moment(this.date).toDate()
          d.setHours(startingHour)
          d.setMinutes(startingMinutes)
          d.setSeconds(i)

          const myMoment = moment(
            fb.db.app.firebase_.firestore.Timestamp.fromDate(d).toDate()
          )

          if (moment(this.date).isSame(moment(), 'day')) {
            if (myMoment.isAfter(moment())) {
              const time = myMoment.format('hh:mm A')

              let indexStart = this.todaysAppointments.findIndex(
                e => e.start.format('hh:mm A') === time
              )
              // * Adds times to be eliminated
              if (indexStart !== -1) {
                eliminate.push({
                  a: this.todaysAppointments[indexStart].start.format(
                    'hh:mm A'
                  ),
                  b: this.todaysAppointments[indexStart].end.format('hh:mm A')
                })
              }

              times.push(time)
            }
          } else {
            const time = myMoment.format('hh:mm A')

            let indexStart = this.todaysAppointments.findIndex(
              e => e.start.format('hh:mm A') === time
            )
            // * Adds times to be eliminated
            if (indexStart !== -1) {
              eliminate.push({
                a: this.todaysAppointments[indexStart].start.format('hh:mm A'),
                b: this.todaysAppointments[indexStart].end.format('hh:mm A')
              })
            }

            times.push(time)
          }
        }
      }

      let count = 0
      let timeIndex = 0
      let timeIndex2 = 0
      let removableIndexes = []
      // * Maps indexes of hours to remove
      while (count < eliminate.length) {
        timeIndex = times.findIndex(e => e === eliminate[count].a)
        timeIndex2 = times.findIndex(e => e === eliminate[count].b)
        if (timeIndex !== -1 && timeIndex2 !== -1) {
          removableIndexes.push({ a: timeIndex, b: timeIndex2 })
        }
        count++
      }

      // * Finds the range of hours to remove
      let remove = []
      for (let i = 0; i < removableIndexes.length; i++) {
        let val = removableIndexes[i]
        let diff = Math.abs(val.a - val.b)
        if (diff == 1) {
          remove.push(val.a)
        } else {
          for (let k = 0; k < diff; k++) {
            remove.push(val.a + k)
          }
        }
      }

      // * Saves hours to remove
      let final = []
      for (let m = 0; m < remove.length; m++) {
        final.push(times[remove[m]])
      }

      // * Removes taken hours
      for (let k = 0; k < final.length; k++) {
        let i = times.findIndex(e => e === final[k])
        if (i !== -1) {
          times.splice(i, 1)
        }
      }

      return times
    },
    times2: function() {
      const times = []
      const timesLimitted = []
      const secondsInDay = 86400
      const steps = 60

      for (let i = 0; i < secondsInDay; i += steps) {
        const d = new Date()
        d.setHours(0)
        d.setMinutes(0)
        d.setSeconds(i)

        const time = moment(
          fb.db.app.firebase_.firestore.Timestamp.fromDate(d).toDate()
        ).format('hh:mm A')

        times.push(time)
      }

      const timesStart = times.indexOf('07:00 AM')
      let index = timesStart

      while (index !== times.length) {
        timesLimitted.push(times[index])
        index++
      }

      return timesLimitted
    }
  },
  async mounted() {
    await fb.db
      .collection('Specialties')
      .get()
      .then(snapshot => {
        for (const item of snapshot.docs) {
          this.specialties.push(item.data().name)
        }
      })
    const currentDoctor = await fb.proxiesCollection
      .doc(this.patient.doctorId)
      .get()
    this.practice.id = currentDoctor.data().practiceId

    if (currentDoctor.data().firstAppt) {
      this.firstAppt = currentDoctor.data().firstAppt
    } else {
      this.firstAppt = 15
    }

    if (currentDoctor.data().returningAppt) {
      this.returningAppt = currentDoctor.data().returningAppt
    } else {
      this.returningAppt = 30
    }

    const patients = await fb.patientsCollection
      .where(this.practice.id, '==', true)
      .get()

    for (const item of patients.docs) {
      const data = item.data()
      const displayName = item.data().fname + ' ' + item.data().lname
      this.patients.push({ id: item.id, displayName: displayName, ...data })
    }

    const doctors = await fb.proxiesCollection
      .where('role', '==', 'Doctor')
      .where('active', '==', true)
      .get()

    for (const item of doctors.docs) {
      const data = item.data()
      const displayName = 'Dr. ' + item.data().fname + ' ' + item.data().lname
      this.doctors.push({
        id: item.id,
        Name: `${displayName} - ${data.specialty.name}`,
        ...data
      })
    }
    this.filteredDoctors = this.doctors

    this.selectedDoctor = this.doctors.find(x => x.id === currentDoctor.id)
    this.selectedPatient = this.patients.find(x => x.id === this.patient.id)
  },
  methods: {
    close() {
      this.$emit('closeAddAppt')
    },
    allowedDates(val) {
      if (this.allowedDays.indexOf(val) !== -1) {
        return true
      } else {
        return false
      }
    },
    async createAppt() {
      if (
        this.$refs.form.validate() &&
        this.selectedDoctor &&
        this.startTime &&
        this.endTime &&
        this.date
      ) {
        this.start = fb.db.app.firebase_.firestore.Timestamp.fromDate(
          new Date(`${this.dateNoDash} ${this.startTime}`)
        )
        this.end = fb.db.app.firebase_.firestore.Timestamp.fromDate(
          new Date(`${this.dateNoDash} ${this.endTime}`)
        )

        try {
          const appointment = await fb.db
            .collection('Practices')
            .doc(this.selectedDoctor.practiceId)
            .collection('Appointments')
            .doc()
          const appId = appointment.id

          this.path = '?code=' + appId + '&p=' + this.selectedDoctor.practiceId

          this.url =
            process.env.VUE_APP_URL +
            'room?code=' +
            appId +
            '&p=' +
            this.selectedDoctor.practiceId

          appointment.set(
            {
              patientId: this.selectedPatient.id,
              doctorId: this.selectedDoctor.id,
              uniqueRoom: appId,
              url: this.url,
              path: this.path,
              immediate: false,
              firstTime: this.firstTime,
              adHoc: false,
              isWaiting: false,
              ekoConnected: false,
              recordTime: null,
              startRecEkoFromDoc: false,
              components: [],
              medicalHistory: [],
              medicalHistoryQuestions: null,
              vitals: {
                painscale: null,
                pulse: null,
                pulseox: null,
                resprate: null,
                temperature: null,
                systolic: null,
                diastolic: null
              },
              isExamComplete: false,
              vitalStamp: null,
              created: fb.db.app.firebase_.firestore.Timestamp.fromDate(
                new Date()
              ),
              startTime: this.start,
              endTime: this.end
            },
            { merge: true }
          )
        } catch (error) {
          this.error = error.message
          console.log(error)
        }

        this.sendEmailNotification()
        this.$emit('closeAddAppt')
      }
    },
    sendEmailNotification() {
      const appointmentCreatedEmailNotification = fb.functions.httpsCallable(
        'appointmentCreatedEmailNotification'
      )

      try {
        appointmentCreatedEmailNotification({
          email: this.selectedPatient.email,
          userId: this.selectedPatient.id,
          appointmentDate: `${this.computedStartTime} - ${this.computedEndTime}`,
          appointmentPath: this.path
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
