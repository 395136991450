import { render, staticRenderFns } from "./MyAppointments.vue?vue&type=template&id=2f270a1d"
import script from "./MyAppointments.vue?vue&type=script&lang=js"
export * from "./MyAppointments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports