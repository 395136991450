<template>
  <v-card max-width="500px">
    <v-container>
      <v-card-title>
        Choose Doctor
        <v-spacer></v-spacer>
        <v-btn @click="close()" color="red" text>Cancel</v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-autocomplete
            v-model="selectedSpecialty"
            :items="specialties"
            label="Filter Specialty"
            outlined
            rounded
            dense
          ></v-autocomplete>

          <v-autocomplete
            v-model="selectedDoctor"
            :items="filteredDoctors"
            item-text="Name"
            item-value="id"
            label="Doctor"
            return-object
            no-data-text="No Doctors Found"
            dense
            outlined
            rounded
            required
            :rules="rules"
          ></v-autocomplete>
          <div v-if="!open">Immediate Waiting Room is currently closed.</div>
          <v-btn
            @click="joinWaitingRoom"
            :disabled="!valid || !open"
            color="primary"
            block
            >Join</v-btn
          >
        </v-form>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import * as fb from '@/firebase'

export default {
  name: 'JoinImmediateDialog',
  props: ['patient'],
  data() {
    return {
      valid: false,
      practiceId: '',
      doctors: [],
      selectedSpecialty: 'Any',
      specialties: ['Any'],
      filteredDoctors: [],
      selectedDoctor: null,
      immediateHours: [],
      open: false,
      rules: [v => !!v || 'Required'],
      doctorsCollection: null,
      currentDoctor: null
    }
  },
  watch: {
    practiceId: {
      immediate: true,
      handler() {
        this.$bind(
          'doctorsCollection',
          fb.proxiesCollection
            .where('role', '==', 'Doctor')
            .where('active', '==', true)
        )
      }
    },
    doctorsCollection: {
      immediate: true,
      handler() {
        if (this.doctorsCollection) {
          for (const item of this.doctorsCollection) {
            const displayName = 'Dr. ' + item.fname + ' ' + item.lname
            this.doctors.push({
              id: item.id,
              Name: `${displayName} - ${item.specialty.name}`,
              ...item
            })
          }
          this.filteredDoctors = this.doctors
          if (this.currentDoctor) {
            this.selectedDoctor = this.doctors.find(
              x => x.id === this.currentDoctor.id
            )
          }
        }
      }
    },
    selectedSpecialty: function() {
      this.selectedDoctor = null
      if (this.selectedSpecialty === 'Any') {
        this.filteredDoctors = this.doctors
      } else {
        this.filteredDoctors = this.doctors.filter(
          x => x.specialty.name === this.selectedSpecialty
        )
      }
    },
    selectedDoctor: function() {
      if (this.selectedDoctor) {
        this.$bind(
          'doctorLink',
          fb.db.doc(`Doctor_Links/${this.selectedDoctor.id}`)
        )
        this.open = this.selectedDoctor.isWaitingRoomOpen
      }
    }
  },
  async mounted() {
    await fb.db
      .collection('Specialties')
      .get()
      .then(snapshot => {
        for (const item of snapshot.docs) {
          this.specialties.push(item.data().name)
        }
      })

    if (this.patient.doctorId) {
      this.$bind(
        'currentDoctor',
        fb.proxiesCollection.doc(this.patient.doctorId)
      ).then(() => {
        this.practiceId = this.currentDoctor.practiceId
      })
    }
  },
  methods: {
    close() {
      this.$emit('closeJoin')
    },
    joinWaitingRoom() {
      this.$router.push(`/${this.doctorLink.doctorLink}`)
    }
  }
}
</script>
