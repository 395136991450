<template>
  <div class="appointments">
    <v-container>
      <div class="d-flex align-center">
        <h1>Upcoming Appointments</h1>
        <v-spacer></v-spacer>
        <div v-if="$mq !== 'mobile'">
          <v-btn
            :block="$mq === 'mobile'"
            @click="joinImmediateDialog = true"
            outlined
            color="primary"
            >Join Immediate Waiting Room</v-btn
          >
          <v-btn
            @click="addAppointment()"
            :block="$mq === 'mobile'"
            :class="$mq === 'mobile' ? 'mt-2' : 'ml-2'"
            color="primary"
            >Add Appointment</v-btn
          >
        </div>
      </div>

      <div v-if="$mq === 'mobile'">
        <v-btn
          :block="$mq === 'mobile'"
          @click="joinImmediateDialog = true"
          outlined
          color="primary"
          >Join Immediate Waiting Room</v-btn
        >
        <v-btn
          @click="addAppointment()"
          :block="$mq === 'mobile'"
          :class="$mq === 'mobile' ? 'mt-2' : 'ml-2'"
          color="primary"
          >Add Appointment</v-btn
        >
      </div>

      <v-data-table
        :headers="headers"
        :items="appointments"
        rounded="lg"
        class="mt-4"
      >
        <template v-slot:item.proxy="{ item }">
          <v-list-item-avatar>
            <img v-if="!item.doctor.photoURL" src="@/assets/avatar.png" />
            <img v-else :src="item.doctor.photoURL" />
          </v-list-item-avatar>
          {{ item.doctor.name }}
        </template>
        <template v-slot:item.startTime="{ item }">
          {{ computedStartTime(item.startTime) }}
        </template>
        <template v-slot:item.link="{ item }">
          <v-btn
            color="primary"
            class="mr-2"
            :small="$mq === 'tablet'"
            :x-small="$mq === 'mobile'"
            @click="supplementalInfo(item)"
          >
            Supplemental Info
          </v-btn>
          <v-btn
            color="primary"
            outlined
            class="mr-2"
            :disabled="!item.inRange"
            :small="$mq === 'tablet'"
            :x-small="$mq === 'mobile'"
            @click="startCall(item)"
          >
            Start Call
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog max-width="500px" v-model="addAppointmentDialog">
        <PatientAddAppointmentDialog
          :patient="patient"
          @closeAddAppt="addAppointmentDialog = false"
          :key="patient.id"
        />
      </v-dialog>
      <v-dialog max-width="500px" v-model="joinImmediateDialog">
        <JoinImmediateDialog
          :patient="patient"
          @closeJoin="joinImmediateDialog = false"
          :key="patient.id"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import PatientLayout from '@/layouts/PatientLayout'
import * as fb from '../firebase'
import * as moment from 'moment'
import PatientAddAppointmentDialog from '@/patientViews/components/AddAppointmentDialog'
import JoinImmediateDialog from '@/patientViews/components/JoinImmediateDialog'

export default {
  name: 'MyAppointments',
  components: {
    PatientAddAppointmentDialog,
    JoinImmediateDialog
  },
  data() {
    return {
      userId: '',
      addAppointmentDialog: false,
      joinImmediateDialog: false,
      appointments: [],
      practices: [],
      appointmentsCollection: [],
      patient: {},
      headers: [
        { text: 'Physician', value: 'proxy' },
        { text: 'Time', value: 'startTime', sortable: true },
        {
          text: '',
          align: 'end',
          value: 'link'
        }
      ]
    }
  },
  watch: {
    appointmentsCollection: {
      immediate: true,
      handler() {
        if (this.appointmentsCollection.length > 0) {
          this.appointments = []
          for (const item of this.appointmentsCollection) {
            let doctor = {}
            let returnTime = 15
            fb.db
              .doc(`Proxies/${item.doctorId}`)
              .get()
              .then(doc => {
                returnTime = doc.data().returningAppt
                doctor = {
                  name: `${doc.data().displayName}`,
                  photoURL: doc.data().photoURL
                }
              })

            let inRange = false
            const startTime = item.startTime.toDate()
            const endTime = item.endTime.toDate()
            if (
              moment().isBetween(
                moment(startTime).subtract(10, 'minutes'),
                moment(endTime).add(returnTime, 'minutes')
              )
            ) {
              inRange = true
            } else {
              inRange = false
            }
            this.appointments.push({
              returnTime: returnTime,
              id: item.id,
              ...item,
              inRange: inRange,
              doctor: { ...doctor }
            })
          }
        }
      }
    }
  },
  async created() {
    this.$emit(`update:layout`, PatientLayout)

    window.setInterval(() => {
      if (this.appointments) {
        for (const item of this.appointments) {
          const startTime = item.startTime.toDate()
          const endTime = item.endTime.toDate()

          if (
            moment().isBetween(
              moment(startTime).subtract(10, 'minutes'),
              moment(endTime).add(item.returnTime, 'minutes')
            )
          ) {
            item.inRange = true
          } else {
            item.inRange = false
          }
        }
      }
    }, 30000)
    this.userId = fb.auth.currentUser.uid
    await this.$bind('patient', fb.patientsCollection.doc(this.userId))

    const timeSevenDays = moment().subtract(1, 'days')
    const timeNow = fb.db.app.firebase_.firestore.Timestamp.fromDate(
      timeSevenDays.toDate()
    )
    this.appointments = []
    this.$bind(
      'appointmentsCollection',
      fb.db
        .collectionGroup(`Appointments`)
        .where('patientId', '==', this.userId)
        .where('startTime', '>', timeNow)
        .orderBy('startTime', 'desc')
    )
  },

  methods: {
    computedStartTime(startTime) {
      return startTime
        ? moment(startTime.toDate()).format('dddd, MMMM Do YYYY hh:mm A')
        : ''
    },
    computedEndTime(endTime) {
      return endTime ? moment(this.end.toDate()).format('hh:mm A') : ''
    },
    startCall(item) {
      this.$router.push(this.$route.query.redirect || '/room' + item.path)
    },
    supplementalInfo(item) {
      this.$router.push(
        this.$route.query.redirect ||
          '/my-exam' + item.path + '&pid=' + item.patientId
      )
    },
    addAppointment() {
      this.addAppointmentDialog = true
    }
  }
}
</script>
